import React from 'react';
import classNames from 'clsx';
import { createUseStyles } from 'react-jss';
import Typography from 'components/typography';
import email from 'consts/email';

function Footer() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, 'bg-animation')}>
      <div className={classes.content}>
        <Typography variant="body1">
          Contact me at{' '}
          <a href={`mailto:${email}`} className={classes.link}>
            {email}
          </a>
        </Typography>
      </div>
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      minHeight: 100,
      // paddingBottom: theme.spacing.unit * 10,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      maxWidth: theme.width.page,
      textAlign: 'center',
    },
    link: {
      color: theme.palette.primary.contrastText,
    },
  };
});

export default Footer;
