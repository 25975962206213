import React from 'react';
import { createUseStyles } from 'react-jss';
import Navigation from 'components/navigation';
import ContactMe from 'components/contact-me';
import Footer from 'components/footer';

interface Props {
  children: React.ReactNode;
}

function Layout({ children }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div id="scroll-content" className={classes.content}>
        <Navigation />
        {children}
        <ContactMe />
        <Footer />
      </div>
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    content: {
      flex: 1,
      overflow: 'auto',
      position: 'relative',
      marginTop: theme.height.navigation,
    },
  };
});

export default Layout;
