module.exports = {
  primary: {
    light: undefined,
    main: '#6470ff',
    dark: undefined,
    contrastText: '#fff',
  },
  secondary: {
    light: undefined,
    main: '#7a4cff',
    dark: undefined,
    contrastText: '#fff',
  },
  common: {
    white: '#fff',
    grey: '#d3d3d3',
    black: '#3a3a3a',
  },
  defaultText: '#444444',
};
