const palette = require('./palette');
const sizing = require('./sizing');
const typography = require('./typography');

const SPACING_UNIT = 8;

const theme = {
  palette,
  ...sizing,
  zIndex: {
    surface: 1,
  },
  spacing: {
    unit: SPACING_UNIT,
    lineBreak: SPACING_UNIT * 2,
    sectionBreak: SPACING_UNIT * 25,
    sectionBreakMobile: SPACING_UNIT * 15,
  },
  transition: {
    normal: '.3s',
  },
  media: {
    md: '@media (max-width: 991px)',
    sm: '@media (max-width: 630px)',
    xs: '@media (max-width: 480px)',
  },
};

theme.typography = typography(theme);

module.exports = theme;
