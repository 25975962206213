import React from 'react';
import classNames from 'clsx';
import { createUseStyles } from 'react-jss';

function Footer() {
  const classes = useStyles();
  const currentTime = new Date();
  const currentYear = currentTime.getFullYear();
  return (
    <div className={classNames(classes.root, 'bg-animation')}>
      <div className={classes.content}>
        <div className={classes.text}>JulesPatry.com &copy;{currentYear}</div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      padding: theme.spacing.unit,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.secondary.contrastText,
      display: 'flex',
      justifyContent: 'center',
    },
    content: {
      textAlign: 'center',
    },
    title: {
      fontSize: 14,
    },
    text: {
      fontSize: 13,
    },
  };
});

export default Footer;
