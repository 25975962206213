module.exports = {
  width: {
    page: 700,
    card: 400,
  },
  height: {
    navigation: 40,
    CTA: 300,
  },
};
