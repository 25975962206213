import React from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

interface Props {
  src?: string;
  size?: number;
  alt?: string;
  children?: React.ReactNode;
  className?: string;
  imageClassName?: string;
  imageSize?: number;
}

function Avatar({
  className,
  imageClassName,
  size = 100,
  src = '',
  children,
  alt = '',
  imageSize,
}: Props) {
  const classes = useStyles();
  if (!src) {
    return (
      <div
        className={clsx(classes.root, className)}
        style={{ width: size, height: size }}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.root, className)}
      style={{ width: size, height: size }}
    >
      <img
        src={src}
        alt={alt}
        className={clsx(classes.image, imageClassName)}
        style={{ width: imageSize ? imageSize : size * 0.75 }}
      />
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      borderRadius: '50%',
      border: `1px solid ${theme.palette.common.black}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      backgroundColor: '#fff',
    },
    image: {
      height: 'auto',
    },
    emptyRoot: {
      borderRadius: '50%',
      border: `1px solid ${theme.palette.common.black}`,
      backgroundColor: theme.palette.primary.main,
    },
  };
});

export default Avatar;
