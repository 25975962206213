import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'clsx';
import { createUseStyles } from 'react-jss';
import Avatar from 'components/avatar';
import theme from 'styles/theme';

const AVATAR_HEIGHT = theme.height.navigation + 32;

function Navigation() {
  const classes = useStyles();
  const [showAvatar, setShowAvatar] = useState(false);

  const listener = useCallback(
    function listener() {
      const scrollPastId = document.getElementById('nav-fade-in');
      if (scrollPastId) {
        const idScrollPoint = scrollPastId.offsetTop;
        const windowScrollTop = document.getElementById('scroll-content')
          .scrollTop;
        if (windowScrollTop > idScrollPoint && !showAvatar) {
          setShowAvatar(true);
        } else if (windowScrollTop < idScrollPoint && showAvatar) {
          setShowAvatar(false);
        }
      }
    },
    [setShowAvatar, showAvatar]
  );

  useEffect(() => {
    if (!document.getElementById('nav-fade-in')) {
      setTimeout(() => {
        setShowAvatar(true);
      }, 200);
    }
  }, [setShowAvatar]);

  useEffect(() => {
    document
      .getElementById('scroll-content')
      .addEventListener('scroll', listener);

    return () =>
      document
        .getElementById('scroll-content')
        .removeEventListener('scroll', listener);
  }, [listener]);

  return (
    <div className={classNames(classes.root, 'bg-animation')}>
      <div className={classes.content}>
        <div
          className={classes.leftContent}
          style={{ opacity: showAvatar ? 1 : 0 }}
        >
          <Avatar
            size={AVATAR_HEIGHT}
            alt="Jules Patry"
            src={'/jules-patry.jpeg'}
            className={classes.avatarClassName}
            imageClassName={classes.imageClassName}
          />
          <h2 className={classes.name}>Jules Patry</h2>
        </div>
        <div className={classes.routes}>
          <a
            target="_blank"
            rel="noopener"
            href="https://docs.google.com/document/d/1Iu4qvJxgu6essvAhS-9jVvmZ3rWc3kgUSvXcabM1kgg/edit?usp=sharing"
          >
            <div>Resume</div>
          </a>
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'center',
      width: '100vw',
      zIndex: theme.zIndex.surface,
      height: theme.height.navigation,
    },
    avatarClassName: {
      overflow: 'hidden',
      position: 'relative',
    },
    imageClassName: {
      top: -10,
      left: -18,
      width: '125px !important',
      position: 'absolute',
    },
    content: {
      maxWidth: 1400,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing.unit * 5,
      paddingRight: theme.spacing.unit,

      [theme.media.xs]: {
        paddingLeft: theme.spacing.unit,
      },
    },
    leftContent: {
      display: 'flex',
      paddingTop: theme.spacing.unit / 2,
      transition: 'opacity .4s',
      '& > div:first-child': {
        marginRight: theme.spacing.unit,
      },
    },
    name: {
      fontSize: 25,
      margin: 0,
      marginTop: theme.spacing.unit,
      [theme.media.sm]: {
        fontSize: 21,
      },
    },

    routes: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 2,
      marginBottom: 2,
      '& > a': {
        color: theme.palette.common.white,
        fontSize: 14,
        textDecoration: 'none',
        transition: 'all .4s',
        padding: theme.spacing.unit,
        borderRadius: 4,
      },
      '& > a:hover': {
        textDecoration: 'underline',
      },
      '& > *:not(:last-child)': {
        marginRight: theme.spacing.unit * 2,
      },
    },
  };
});

export default Navigation;
