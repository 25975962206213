import React from 'react';
import { createUseStyles } from 'react-jss';

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'overline';

interface Props {
  variant: Variant;
  mb?: string;
  children: React.ReactNode | string;
  className?: string;
}

export default function Typography({
  variant,
  mb,
  children,
  className,
}: Props) {
  const classes = useStyles();
  const rootClassName = className
    ? className + ' ' + classes[variant] + ' ' + classes[mb]
    : classes[variant] + ' ' + classes[mb];

  const props = {
    className: rootClassName,
  };

  return getHtmlNode(variant, props, children);
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {},
    // Variants
    h1: theme.typography.h1,
    h2: theme.typography.h2,
    h3: theme.typography.h3,
    h4: theme.typography.h4,
    h5: theme.typography.h5,
    h6: theme.typography.h6,
    subtitle1: theme.typography.subtitle1,
    subtitle2: theme.typography.subtitle2,
    body1: theme.typography.body1,
    body2: theme.typography.body2,
    button: theme.typography.button,
    caption: theme.typography.caption,
    overline: theme.typography.overline,
    // Spacing
    mb2: {
      marginBottom: theme.spacing.unit * 2,
    },
    mb3: {
      marginBottom: theme.spacing.unit * 3,
    },
    mb4: {
      marginBottom: theme.spacing.unit * 4,
    },
    mb6: {
      marginBottom: theme.spacing.unit * 6,
    },
    mb8: {
      marginBottom: theme.spacing.unit * 8,
    },
    mb10: {
      marginBottom: theme.spacing.unit * 10,
    },
  };
});

function getHtmlNode(variant: Variant, props, children) {
  if (variant === 'h1') {
    return <h1 {...props}>{children}</h1>;
  }

  if (variant === 'h2') {
    return <h2 {...props}>{children}</h2>;
  }

  if (variant === 'h3') {
    return <h3 {...props}>{children}</h3>;
  }

  if (variant === 'h4') {
    return <h4 {...props}>{children}</h4>;
  }

  if (variant === 'h5') {
    return <h5 {...props}>{children}</h5>;
  }

  if (variant === 'h6') {
    return <h6 {...props}>{children}</h6>;
  }

  return <div {...props}>{children}</div>;
}
