// Theme was taken by https://material-ui.com/customization/default-theme/

const baseFontSize = 16;
const headerFontFamily = 'Verdana, Geneva, sans-serif';
const bodyFontFamily = 'Verdana, Geneva, sans-serif';

module.exports = theme => ({
  baseFontSize,
  h1: {
    fontFamily: headerFontFamily,
    fontWeight: 300,
    fontSize: 60,
    lineHeight: 1.167,
    letterSpacing: '-0.01562em',
  },
  h2: {
    fontFamily: headerFontFamily,
    fontWeight: 300,
    fontSize: 42,
    lineHeight: 1.2,
    letterSpacing: '-0.01562em',
    textDecoration: 'underline',
  },
  h3: {
    fontFamily: headerFontFamily,
    fontWeight: 400,
    fontSize: 32,
    lineHeight: 1.167,
    letterSpacing: '0em',
  },
  h4: {
    fontFamily: bodyFontFamily,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.235,
    letterSpacing: '0.00735em',
  },
  h5: {
    fontFamily: bodyFontFamily,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  h6: {
    fontFamily: bodyFontFamily,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },

  subtitle1: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
  },
  subtitle2: {
    fontFamily: bodyFontFamily,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.57,
    letterSpacing: '0.00714em',
  },
  body1: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  body2: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
  button: {
    fontFamily: bodyFontFamily,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  caption: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textDecoration: 'underline',
  },
  overline: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
  },
});
